import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import SwiperCore, { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import SEO from "../components/seo"

import {
  StyledContainer,
  Product,
  ProductContent,
} from "../components/products/styles"

SwiperCore.use([Navigation, Pagination])

const ArmariosColmeias = () => {
  const data = useStaticQuery(graphql`
    query {
      ab2portas: file(relativePath: { eq: "products/ab-2-portas.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ab2portas2: file(relativePath: { eq: "products/ab-2-portas2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      colmeia09nichos: file(
        relativePath: { eq: "products/colmeia-09-nichos.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      colmeia09nichos2: file(
        relativePath: { eq: "products/colmeia-09-nichos2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO title="Armários e Colmeias" />

      <StyledContainer as="section">
        <Product>
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ type: "fraction" }}
            // navigation
          >
            <SwiperSlide>
              <Img fluid={data.ab2portas.childImageSharp.fluid} />
            </SwiperSlide>
            <SwiperSlide>
              <Img fluid={data.ab2portas2.childImageSharp.fluid} />
            </SwiperSlide>
          </Swiper>

          <ProductContent>
            <h3>Armário Baixo com 2 Portas</h3>

            <ul>
              <li>Altura: 65 cm </li>
              <li>Largura: 90 cm</li>
              <li>Profundidade: 42 cm</li>
              <li>Pés rodízio</li>
            </ul>
          </ProductContent>
        </Product>

        <Product>
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ type: "fraction" }}
            // navigation
          >
            <SwiperSlide>
              <Img fluid={data.colmeia09nichos.childImageSharp.fluid} />
            </SwiperSlide>
            <SwiperSlide>
              <Img fluid={data.colmeia09nichos2.childImageSharp.fluid} />
            </SwiperSlide>
          </Swiper>

          <ProductContent>
            <h3>Colmeia - 09 Nichos</h3>

            <ul>
              <li>Altura: 100 cm </li>
              <li>Largura: 100 cm</li>
              <li>Profundidade: 30 cm</li>
              <li>Pés rodízio</li>
            </ul>
          </ProductContent>
        </Product>
      </StyledContainer>
    </>
  )
}

export default ArmariosColmeias
